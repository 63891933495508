#main {
    width: 100%;
    height: 601px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/imgHeader.jpeg");
    position: relative;
    z-index: 0;
  }
  #main::before {
    position: absolute;
    content: "";
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(195, 195, 195, 0.38) 0%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(70, 70, 70, 0.38) 0%,
      rgba(68, 68, 68, 0.6) 100%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(25, 25, 25, 0.38) 0%,
      rgba(59, 59, 59, 0.6) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .header-heading {
    text-align: center;
    width: 900px;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -55%);
  }
  .header-heading span {
    color: #ffeb14;
  }
  .header-heading .details {
    font-size: 23px;
    color: #c5c5c5;
  }
  .header-heading h2 {
    font-size: 4rem;
    margin: 0px;
    letter-spacing: 2px;
    color: #ffffff;
  }
  .header-heading h1 {
    font-size: 5rem;
    margin: 0px;
    letter-spacing: 2px;
    color: #ffffff;
  }
  .header-btns {
    display: flex;
    margin-top: 40px;
    margin-left: 30%;
  }
  .header-btn {
    width: 350px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #149dff;
    box-shadow: 5px 10px 30px rgba(255, 255, 255, 0.336);
    border-radius: 5px;
    color: #ffffff;
  }
  .header-btn:hover {
    background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #ffeb14;
  }

  @media (max-width: 970px) {
    #main {
      height: 680px;
    }
  }

  @media (max-width: 600px) {
    #main {
      background-position: center;
    }
  

    .header-heading{
      width: 100%;
      top: 58%;
    }

    .header-heading h2 {
       font-size: 2.8rem;
    }

    .a-container {
      flex-wrap: wrap;
    }

    .header-btns{
      margin-left: 5%;
    }
  
    .a-box{
      width: 100%;
      height: 400px;
  }
  
    .header-heading h1 {
      font-size: 2.2rem;
    }
    .pr-heading {
      width: 60%;
    }
    #presentaion {
      background-position: center;
    }
  }