.work__container {
    width: 90%;
 }
 
 .work__card {
    padding: 1.5rem 2rem;
    margin-bottom: var(--mb-3);
    border-radius: 1.25rem;
    margin-right: 50px;
 }
 
 .work__img {
     width: 90%;
     margin-bottom: var(--mb-1);
     border-radius: 1.25rem;
 }

 .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #149dff;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
    margin-left: 14px;
   }
   
   .btn:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #FFD401;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
   }
   
   .btn:hover::after {
    right: auto;
    left: 0;
    width: 100%;
   }
   
   .btn span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 18px 25px;
    color: #fff;
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 0.3em;
    z-index: 20;
    transition: all 0.3s ease-in-out;
   }
   
   .btn:hover span {
    color: #183153;
    animation: scaleUp 0.3s ease-in-out;
   }
   
   @keyframes scaleUp {
    0% {
     transform: scale(1);
    }
   
    50% {
     transform: scale(0.95);
    }
   
    100% {
     transform: scale(1);
    }
}
 
 .work__name {
     font-size: var(--normal-font-size);
     font-weight: var(--font-medium);
     margin-bottom: var(--mb-0-25);
 }
 
 .work__description {
     font-size: var(--small-font-size);
 }

 .swiper-pagination-bullet {
     background-color: var(--text-color) !important;
 }
 
 .swiper-pagination-bullet-active {
     background-color: var(--title-color) !important;
 }
 
 @media screen and (max-width: 992px) {
     .work__container {
         width: initial;
     }
 
     .work__card {
         padding: 1.25rem 1.5rem;
         margin-left: 3px;
     }

     .btn{
        width: 80%;
     }
 }