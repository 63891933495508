.parallax-corte2{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0.6) 100%), url("../../assets/parallax_img.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-section {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.py-5{
    text-align: center;
}

.titulos-seccion {
    font-size: 2.2rem !important;
}

.text-white {
    color: #fff!important;
}

.mt-3, .my-3 {
    margin-top: 1rem!important;
    margin-left: 10%;
}

.frases {
    font-size: 1.5rem !important;
}

.pl-4, .px-4 {
    padding-left: 1.5rem!important;
}
.pr-4, .px-4 {
    padding-right: 1.5rem!important;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}
.mt-3, .my-3 {
    margin-top: 1rem!important;
}
.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.button22 {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #ffffff;
    padding: 0.7em 1.7em;
    font-size: 18px;
    border-radius: 0.5em;
    background: #ffffff00;
    border: 1px solid #e8e8e8;
    margin-left: 10%;
    margin-top: 15px;
    cursor: pointer;
}
  
  .button22:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5,
               inset -4px -4px 12px #ffffff;
  }
  
  .button22:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .button22:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #009087;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .button22:hover {
    color: #5b5b5b;
    border: 1px solid #829000;
  }
  
  .button22:hover:before {
    top: -35%;
    background-color: #ffff50;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .button22:hover:after {
    top: -45%;
    background-color:#ffeb14;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }

  .text_parallax2{
    color: white;
    margin-top: 20px;
  }
  

  @media screen and (max-width: 768px) {
  .py-5 {
    margin-left: 24px;
  }

  .mt-3{
    margin-left: 0px;
  }

  .button22{
    margin-left: 0px;
  }

}